import React from 'react';
import Auth from '../auth/Auth';

const Core: React.FC = () => {
    return (
        <div>
            <Auth />
        </div>
    );
};

export default Core;
